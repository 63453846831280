<template>
	<Row class="hero ivu-text-center">
		<Col span="24">
		<Title><span style="color: #2b85e4;">外贸518</span>工具团购</Title>
		<Title :level="3">稳定，方便 的外贸工具团购,助力外贸中小型卖家成长！</Title>
		</Col>
	</Row>
	<br>
	<ProductList></ProductList>
</template>

<script>
	import ProductList from '@/components/ProductList.vue'
	export default {
		name: 'HomeView',
		components: {
			ProductList
		}
	}
</script>

<style>
	.hero {
		background-color: rgb(239, 245, 251);
		padding: 80px;
	}
</style>